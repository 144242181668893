var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.style.container},[_c('div',{class:_vm.style.textContainer},[_c('h2',{class:_vm.style.header},[_vm._v("Kontakt")]),_c('p',{class:_vm.style.description},[_vm._v(_vm._s(_vm.contactHeader))]),_c('form',{class:_vm.style.form,on:{"submit":_vm.onSubmit}},[_c('div',{class:_vm.style.formControlContainer},[_c('label',{class:_vm.style.label,attrs:{"for":"name"}},[_vm._v("Imię")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formName),expression:"formName"}],class:[
            _vm.style.formControl,
            _vm.style.input,
            !_vm.isNameValid ? _vm.style.invalidInput : null ],attrs:{"id":"name","maxlength":20,"minlength":3,"name":"name","placeholder":"Wpisz imię","required":"","type":"text"},domProps:{"value":(_vm.formName)},on:{"change":function($event){return _vm.validateFormField(_vm.FormField.NAME)},"input":function($event){if($event.target.composing){ return; }_vm.formName=$event.target.value}}}),(!_vm.isNameValid)?_c('span',{class:_vm.style.validationMessage,attrs:{"role":"alert"}},[_vm._v(" Imię powinno zawierać od 3 do 20 znaków. ")]):_vm._e()]),_c('div',{class:_vm.style.formControlContainer},[_c('label',{class:_vm.style.label,attrs:{"for":"surname"}},[_vm._v("Nazwisko")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formSurname),expression:"formSurname"}],class:[
            _vm.style.formControl,
            _vm.style.input,
            !_vm.isSurnameValid ? _vm.style.invalidInput : null ],attrs:{"id":"surname","maxlength":30,"minlength":3,"name":"surname","placeholder":"Wpisz nazwisko","required":"","type":"text"},domProps:{"value":(_vm.formSurname)},on:{"change":function($event){return _vm.validateFormField(_vm.FormField.SURNAME)},"input":function($event){if($event.target.composing){ return; }_vm.formSurname=$event.target.value}}}),(!_vm.isSurnameValid)?_c('span',{class:_vm.style.validationMessage,attrs:{"role":"alert"}},[_vm._v(" Nazwisko powinno zawierać od 3 do 30 znaków. ")]):_vm._e()]),_c('div',{class:_vm.style.formControlContainer},[_c('label',{class:_vm.style.label,attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formEmail),expression:"formEmail"}],class:[
            _vm.style.formControl,
            _vm.style.input,
            !_vm.isEmailValid ? _vm.style.invalidInput : null ],attrs:{"id":"email","name":"email","placeholder":"Wpisz adres email","required":"","type":"email"},domProps:{"value":(_vm.formEmail)},on:{"change":function($event){return _vm.validateFormField(_vm.FormField.EMAIL)},"input":function($event){if($event.target.composing){ return; }_vm.formEmail=$event.target.value}}}),(!_vm.isEmailValid)?_c('span',{class:_vm.style.validationMessage,attrs:{"role":"alert"}},[_vm._v(" Niepoprawny email ")]):_vm._e()]),_c('div',{class:_vm.style.formControlContainer},[_c('label',{class:_vm.style.label,attrs:{"for":"message"}},[_vm._v("Wiadomość")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formMessage),expression:"formMessage"}],class:[
            _vm.style.formControl,
            _vm.style.input,
            _vm.style.textarea,
            !_vm.isMessageValid ? _vm.style.invalidInput : null ],attrs:{"id":"message","cols":20,"maxLength":500,"minlength":20,"rows":2,"name":"message","placeholder":"Wpisz wiadomość","required":"","type":"text"},domProps:{"value":(_vm.formMessage)},on:{"change":function($event){return _vm.validateFormField(_vm.FormField.MESSAGE)},"input":function($event){if($event.target.composing){ return; }_vm.formMessage=$event.target.value}}}),_vm._v(" "),(!_vm.isMessageValid)?_c('span',{class:_vm.style.validationMessage,attrs:{"role":"alert"}},[_vm._v(" Wiadomość powinna zawierać od 20 do 500 znaków. ")]):_vm._e()]),_c('button',{class:[_vm.style.formControl, _vm.style.button],attrs:{"aria-label":"Wyślij wiadomość","type":"submit"}},[_c('send-icon',{class:_vm.style.sendIcon,attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("Wyślij")])],1)]),_c('div',{class:_vm.style.contactBox,attrs:{"tabindex":0,"aria-label":"Skopiuj adres email","role":"button"},on:{"click":_vm.debouncedEmailClick}},[_c('mail-icon',{class:_vm.style.mailIcon,attrs:{"size":_vm.isExtraSmallScreen ? 20 : 25,"aria-hidden":"true"}}),_c('p',{class:_vm.style.contactInfo},[_vm._v(_vm._s(_vm.mail))]),_c('copy-icon',{class:_vm.style.copyIcon,attrs:{"size":_vm.isExtraSmallScreen ? 15 : 20,"aria-hidden":"true"}})],1)]),_c('snackbar',{attrs:{"isVisible":_vm.isSnackBarVisible,"variant":_vm.snackbarVariant},on:{"close":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }