





















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import { SnackbarVariant } from '@/typings';

@Component({ components: { CloseIcon } })
export default class Snackbar extends Vue {
  @Prop({ type: Boolean, default: false }) isVisible!: boolean;
  @Prop({ type: String, required: true }) variant!: string;
  @Prop({ type: Boolean, default: false }) isExtraSmallScreen!: boolean;

  @Emit('close') onClose(): void {
    return;
  }

  SnackbarVariant: typeof SnackbarVariant = SnackbarVariant;
}
