






















































































































































import { Component, Vue } from 'vue-property-decorator';
import MailIcon from 'vue-material-design-icons/EmailOutline.vue';
import CopyIcon from 'vue-material-design-icons/ContentCopy.vue';
import SendIcon from 'vue-material-design-icons/EmailSend.vue';
import Snackbar from '@/components/shared/Snackbar.vue';
import { contactHeader, mail, snackbarMessages } from './contactData';
import { SnackbarVariant, SnackbarStatus, FormField } from '@/typings';
import { isEmailValid } from '@/utils';
import emailjs from 'emailjs-com';
import { debounce, DebouncedFunc } from 'lodash';

@Component({ components: { MailIcon, CopyIcon, Snackbar, SendIcon } })
export default class Contact extends Vue {
  FormField: typeof FormField = FormField;
  SnackbarVariant: typeof SnackbarVariant = SnackbarVariant;

  contactHeader: string = contactHeader;
  copiedSuccessfully: boolean = false;
  formEmail: string = '';
  formMessage: string = '';
  formName: string = '';
  formSurname: string = '';
  isEmailValid: boolean = true;
  isMessageValid: boolean = true;
  isNameValid: boolean = true;
  isSnackBarVisible: boolean = false;
  isSurnameValid: boolean = true;
  mail: string = mail;
  messageVariant: string = '';
  sentSuccessfully: boolean = false;

  smallMobileBreakPoint: MediaQueryList = window.matchMedia(
    '(max-width: 375px)'
  );
  isExtraSmallScreen: boolean = this.smallMobileBreakPoint.matches;

  debouncedEmailClick: DebouncedFunc<() => void> = debounce(
    this.onEmailClick,
    200
  );

  mounted(): void {
    this.smallMobileBreakPoint.onchange = this.mediaQueryHandler;
    emailjs.init(process.env.VUE_APP_EMAIL_USER_ID);
  }

  get areAllFormFieldsValid(): boolean {
    return (
      this.isNameValid &&
      this.isSurnameValid &&
      this.isMessageValid &&
      this.isEmailValid
    );
  }

  get snackbarMessage(): string {
    return snackbarMessages[this.messageVariant];
  }

  get snackbarVariant(): SnackbarVariant {
    return this.copiedSuccessfully || this.sentSuccessfully
      ? SnackbarVariant.POSITIVE
      : SnackbarVariant.NEGATIVE;
  }

  clearFormFields(): void {
    this.formName = '';
    this.formSurname = '';
    this.formEmail = '';
    this.formMessage = '';
  }

  mediaQueryHandler(): void {
    this.isExtraSmallScreen = this.smallMobileBreakPoint.matches;
  }

  onClose(): void {
    this.isSnackBarVisible = false;
  }

  onEmailClick(): void {
    try {
      navigator.clipboard.writeText(this.mail);
      this.copiedSuccessfully = true;
      this.messageVariant = SnackbarStatus.COPY_SUCCESS;
      this.isSnackBarVisible = true;

      setTimeout(() => {
        this.isSnackBarVisible = false;
        this.copiedSuccessfully = false;
      }, 5000);
    } catch {
      this.copiedSuccessfully = false;
      this.messageVariant = SnackbarStatus.COPY_ERROR;
      this.isSnackBarVisible = true;

      setTimeout(() => {
        this.isSnackBarVisible = false;
      }, 5000);
    }
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    Object.values(FormField).forEach((field: FormField) =>
      this.validateFormField(field)
    );

    if (this.areAllFormFieldsValid) {
      this.sendEmail(form);
      this.clearFormFields();
    } else {
      this.sentSuccessfully = false;
      this.messageVariant = SnackbarStatus.INVALID_FORM_DATA;
      this.isSnackBarVisible = true;

      setTimeout(() => {
        this.isSnackBarVisible = false;
      }, 5000);
    }
  }

  sendEmail(form: HTMLFormElement): void {
    try {
      emailjs.sendForm(
        process.env.VUE_APP_EMAIL_SERVICE_ID,
        process.env.VUE_APP_EMAIL_TEMPLATE_ID,
        form
      );
      this.sentSuccessfully = true;
      this.messageVariant = SnackbarStatus.SEND_SUCCESS;
      this.isSnackBarVisible = true;

      setTimeout(() => {
        this.isSnackBarVisible = false;
        this.sentSuccessfully = false;
      }, 5000);
    } catch {
      this.sentSuccessfully = false;
      this.messageVariant = SnackbarStatus.SEND_ERROR;
      this.isSnackBarVisible = true;

      setTimeout(() => {
        this.isSnackBarVisible = false;
      }, 5000);
    }
  }

  validateEmail(): void {
    isEmailValid(this.formEmail)
      ? (this.isEmailValid = true)
      : (this.isEmailValid = false);
  }

  validateFormField(field: FormField): void {
    switch (field) {
      case FormField.NAME:
        this.validateName();
        break;
      case FormField.SURNAME:
        this.validateSurname();
        break;
      case FormField.MESSAGE:
        this.validateMessage();
        break;
      case FormField.EMAIL: {
        this.validateEmail();
        break;
      }
    }
  }

  validateMessage(): void {
    this.formMessage.trim().length >= 20 &&
    this.formMessage.trim().length <= 500
      ? (this.isMessageValid = true)
      : (this.isMessageValid = false);
  }

  validateName(): void {
    this.formName.trim().length >= 3 && this.formName.trim().length <= 20
      ? (this.isNameValid = true)
      : (this.isNameValid = false);
  }

  validateSurname(): void {
    this.formSurname.trim().length >= 3 && this.formSurname.trim().length <= 30
      ? (this.isSurnameValid = true)
      : (this.isSurnameValid = false);
  }
}
